import React from 'react';
import Layout from '../components/layout';
import * as styles from './revendeurs.module.scss';
import DemoForm from '../components/DemoForm';
import '../components/layout.scss';
import SEO from '../components/seo';
import configuration from '../images/revendeurs/configuration.jpg';
import dashboard from '../images/revendeurs/tableau-de-bord.jpg';
import rulerWhite from '../images/icons/ruler_white.svg';
import rollup from '../images/icons/rollup.svg';
import devis from '../images/icons/devis.svg';
import interfaceIntuitive from '../images/icons/interface.svg';
import contactHumain from '../images/icons/contact-humain.svg';
import espacePerso from '../images/icons/espace-perso.svg';
import accessibilite from '../images/icons/accessibilité.svg';

const RevendeursPage = () => (
  <Layout>
    <SEO title="Revendeurs" />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">
              La solution Grand Format pour les Revendeurs
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card text-center">
              <p>
                Vous êtes Revendeur de supports imprimés ?<br />
                (Agence de Communication, d’Évènementiel, Enseigniste,
                Imprimeur, Infographiste, Plateforme, Courtier, Organisateur de
                salons, Centre de congrès, …) <br />
                <span className="bold">
                  {' '}
                  Nous vous proposons un outil de calcul 100% en ligne
                  spécialement dédié au Grand Format
                </span>
                .
              </p>
              <p className={styles.big}>
                <span>
                  Gagnez du temps et augmentez vos ventes, sans coût et sans
                  engagement !
                </span>
              </p>
              <p>
                Nous avons développé pour vous{' '}
                <span className="bold">
                  une solution très innovante et très simple{' '}
                </span>
                pour gérer vos impressions Grand Format. <br />
                Il s’agit d’un espace privilégié avec un accès réservé qui vous
                offre un{' '}
                <span className="bold">
                  niveau de prix particulièrement étudié
                </span>
                .
              </p>
              <p className="secondary bold">
                Associant les avantages d’un logiciel et d’un site Web, cette
                plateforme est adaptée aux professionnels des Arts Graphiques.
                <br />
                Elle vous permet de chiffrer et de commander immédiatement vos
                supports Grands Formats fabriqués dans notre atelier :{' '}
              </p>
              <div className="row mb0">
                <div className="col-lg-1" />
                <div className="col-lg-7 col-md-8">
                  <div className={styles.product}>
                    <div className={styles.productImg}>
                      <img src={rulerWhite} />
                    </div>
                    <div className="card bg-white">
                      <h2 className="text-center">Sur-mesure</h2>
                      <ul className="mb0">
                        <li>
                          <span class="bold">
                            « Souples » (jusqu’à 3m20 de laize)
                          </span>{' '}
                          : bâche, papier, adhésif, PVC, magnétique,
                        </li>
                        <li>
                          <span class="bold">« Rigides » </span>: Akilux,
                          Dibond, carton, PVC, Plexiglass,
                        </li>
                      </ul>
                      <p className="m0">
                        et leurs finitions : découpe, lamination, œillets ou
                        blanc de soutien.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className={styles.product}>
                    <div className={styles.productImg}>
                      <img src={rollup} className={styles.rollup} />
                    </div>
                    <div className="card bg-white">
                      <h2 className="text-center">Produits finis</h2>
                      <ul className="mb0">
                        <li>Roll-ups</li>
                        <li>Banners</li>
                        <li className="mb0">Extérieur</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <p className="m0">
                    N.B : pour les formats très spéciaux et l’ensemble de notre
                    offre de supports publicitaires et de communication, notre
                    équipe de deviseurs-fabricants est toujours disponible sur{' '}
                    <a href="mailto:revendeurs@in-print.net">
                      revendeurs@in-print.net
                    </a>{' '}
                    ou au 05 61 24 65 35.
                  </p>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card text-center">
              <p>
                Cette solution vous offre de{' '}
                <span className="bold">nombreux avantages</span> en favorisant{' '}
                <span className="bold">la réactivité et l’autonomie</span>.
                <br />
                Conçues pour un usage quotidien, les fonctionnalités sont
                regroupées et accessibles de façon simple et intuitive.
                <br />
                Enfin, un <span className="bold">tableau de bord</span> vous
                permet de gérer vos devis, vos demandes et vos factures.{' '}
              </p>
              <div class="row">
                <div class="col-lg-1" />
                <div class="col-lg-5 col-md-6">
                  <img class={styles.screenshot} src={configuration} />
                </div>
                <div class="col-lg-5 col-md-6">
                  <img class={styles.screenshot} src={dashboard} />
                </div>
              </div>
              <button
                onClick={() => animateScroll('#avantages')}
                className={`${styles.btn} ${styles.btnPrimary}`}
              >
                En savoir + sur les avantages
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`parallax ${styles.contactForm}`}>
      <div className="container">
        <div className="row mb0">
          <div className="col-lg-12">
            <div className="card text-center bg-light-grey">
              <h2>Vous souhaitez dynamiser vos ventes de Grand Format ?</h2>
              <p>
                <span className="bold">Essayez notre solution !</span>
                <br />
                Pour une{' '}
                <span className="bold">information plus détaillée</span> ou une{' '}
                <span className="bold">
                  demande de présentation personnalisée
                </span>
                , merci de bien vouloir renseigner le formulaire ci-dessous :
                <br />
                <span className="italic">
                  (Pour une simple question, vous pouvez nous contacter sur{' '}
                  <a href="mailto:revendeurs@in-print.net">
                    revendeurs@in-print.net
                  </a>{' '}
                  ou par téléphone au 05 61 24 65 35)
                </span>
              </p>
              <DemoForm />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="avantages">
      <div className="container">
        <div className="row mb0">
          <div className="col-lg-12">
            <div className="card text-center">
              <h2>Les avantages de notre plateforme</h2>
              <div class={`row mb0 ${styles.avantages}`}>
                <div class="col-lg-4 col-md-6">
                  <div className={styles.avantage}>
                    <div className={styles.avantageImg}>
                      <img src={interfaceIntuitive} />
                    </div>
                    <div className="card bg-white">
                      <h3>
                        Une interface <br />
                        complète et intuitive{' '}
                      </h3>
                      <p className="m0">
                        La plateforme se substitue à un{' '}
                        <span class="bold">
                          logiciel traditionnel de devisage
                        </span>{' '}
                        et de suivi des commandes
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div className={styles.avantage}>
                    <div className={styles.avantageImg}>
                      <img src={devis} />
                    </div>
                    <div className="card bg-white">
                      <h3>
                        Un devis <br />
                        instantané{' '}
                      </h3>
                      <p className="m0">
                        L'obtention immédiate du{' '}
                        <span class="bold">prix et</span> du{' '}
                        <span class="bold">délai de livraison</span> pour
                        n'importe quel devis de supports Grand Format sur-mesure
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div className={styles.avantage}>
                    <div className={styles.avantageImg}>
                      <img src={contactHumain} />
                    </div>
                    <div className="card bg-white">
                      <h3>
                        Un système en ligne <br />
                        et un contact humain{' '}
                      </h3>
                      <p className="m0">
                        Une <span class="bold">réactivité sans égale</span>{' '}
                        associée à l’assistance d’un
                        <span class="bold"> spécialiste</span> pour toutes vos
                        questions
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2" />
                <div class="col-lg-4 col-md-6">
                  <div className={styles.avantage}>
                    <div className={styles.avantageImg}>
                      <img src={espacePerso} />
                    </div>
                    <div className="card bg-white">
                      <h3>
                        Un espace <br />
                        personnalisé
                      </h3>
                      <p className="m0">
                        L'<span class="bold">accès sécurisé</span> à votre
                        espace personnel <span class="bold">24h/24</span> avec
                        une possibilité de connexion{' '}
                        <span class="bold">multi-utilisateurs</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div className={styles.avantage}>
                    <div className={styles.avantageImg}>
                      <img src={accessibilite} />
                    </div>
                    <div className="card bg-white">
                      <h3>
                        Une accessibilité <br />
                        garantie
                      </h3>
                      <p className="m0">
                        Cet outil étant un{' '}
                        <span class="bold">logiciel déporté</span>, une simple{' '}
                        <span class="bold">connexion internet</span> vous
                        permettra de passer vos commandes depuis votre PC
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default RevendeursPage;

function animateScroll(targetSelector) {
  const targetPosition = getElementOffsetTop(
    document.querySelector(targetSelector)
  );

  console.log('targetPosition', targetPosition);

  const initialPosition = window.scrollY;
  const SCROLL_DURATION = 30;
  const maxDistance = document.body.scrollHeight - window.innerHeight;

  const scrollDistance =
    maxDistance > targetPosition
      ? targetPosition - initialPosition
      : maxDistance;
  const stepX = Math.PI / SCROLL_DURATION;
  let stepCount = 0;
  if (window.requestAnimationFrame) {
    requestAnimationFrame(step);
  }

  function step() {
    if (stepCount < SCROLL_DURATION) {
      requestAnimationFrame(step);
      stepCount++;
      window.scrollTo(
        0,
        initialPosition +
          scrollDistance * 0.22 * Math.pow(1 - Math.cos(stepX * stepCount), 2)
      );
    }
  }
}

const getElementOffsetTop = (element, currentOffset = 0) => {
  console.log('element', element);

  if (element.offsetParent) {
    return getElementOffsetTop(
      element.offsetParent,
      currentOffset + element.offsetTop
    );
  }

  return currentOffset;
};
